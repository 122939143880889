import litentry from "./litentry";
import statemine from "./statemine";
import statemint from "./statemint";
import westmint from "./westmint";
import litmus from "./litmus";
import westendCollectives from "./westendCollectives";
import collectives from "./collectives";
import kusama from "./kusama";
import polkadot from "./polkadot";
import { ReactComponent as PhalaIcon } from "../../../components/icons/phala.svg";

const phala = {
  name: "Phala",
  icon: <PhalaIcon />,
  icon: polkadot.icon,
  identity: "polkadot",
  value: "phala",
  chain: "phala",
  symbol: "PHA",
  decimals: 12,
  color: "#3765DC",
  colorSecondary: "rgba(55, 101, 220, 0.1)",
  buttonColor: "#000000",
  modules: {
    identity: false,
  },
  nodes: [
    // { name: "Phala", url: "wss://api.phala.network/ws" },
    // { name: "Phala", url: "ws://10.0.0.120:19944" },
    { name: "Phala", url: "wss://poc6.phala.network/ws" },
  ],
  useOnChainBlockData: false,
};


const chains = {
  phala,
  // kusama,
  // polkadot,
  // litentry,
  // litmus,
  // statemine,
  // statemint,
  // westmint,
  // "westend-collectives": westendCollectives,
  // collectives,
};

export default chains;
